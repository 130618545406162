import React from "react"

import Index from "../components/index"
import SEO from "../components/other/seo"
import Container from "../components/layout/container";
import HighlightedParagraph from "../components/elements/highlighted-paragraph";
import MicroSure from "../components/elements/micro-sure";
import ProductsImage from "../components/images/products";

const OurStoryPage = () => (
  <Index>
    <SEO title="Our Story" />
    <Container id="product-overview-banner">
      <h1>Our Story</h1>
    </Container>
    <Container>
      <div id="our-story-top">
        <div className="left">
          <HighlightedParagraph>
            <p>A distinguished combination of 30 years in specialty drug<br />
              distribution and over a decade of innovation by leading<br />
              material and biotechnology experts brings you a revolutionary<br />
              process for sanitizing and protecting your person, home,<br />
              workspace, and other environments.</p>
          </HighlightedParagraph>
          <p>
            The <MicroSure /> product line, developed by Strategia and distributed<br />
            by FFF Enterprises, produces a “Mechanical Kill” barrier on surfaces<br />
            that destroys the cell wall of harmful pathogens as they encounter <br/>
            the protected <MicroSure /> surface.</p>
        </div>
        <div className="right">
          <ProductsImage />
        </div>
      </div>
      <div id="our-story-text">
        <h2>A Flawless Reputation of Channel<br />Integrity and Customer Dedication </h2>
        <p>Founded in 1988, FFF Enterprises Inc. is the nation’s largest and most trusted distributor of plasma products, biopharmaceuticals, vaccines, and other specialty pharmaceuticals. FFF’s unwavering commitment to Guaranteed Channel Integrity™ has ensured the safe and secure distribution for healthcare providers and their patients for over 30 years. FFF works tirelessly to fulfill its mission Helping Healthcare Care® by understanding that a patient is at the end of every transaction.  FFF’s integrous precision and personal approach to supply chain is the foundation of the multi-billion-dollar organization and has positioned it to proactively respond to opportunities that further advance patient health and safety.  </p>
        <h2>Innovation That Saves Lives Across the Globe</h2>
        <p>Comprised of board-certified team of healthcare experts, Strategia Project Management Inc. is committed to research and innovation of health care products that save lives and provide solutions to global healthcare crises. With a combined experience of over 160 years in healthcare, the team of experts push scientific and technological boundaries to produce revolutionary market offerings. Like the <MicroSure /> product line, these offerings enhance patient care and promote global health through proactive methods. Dr. Erwin Lo, neurosurgeon and founder, collaborated with other health experts over a decade ago to develop the unique soluble antimicrobial solution that bonds to skin and other surfaces. This novel innovation provides a safer and longer lasting alternative to traditionally used “chemical kill” products. When applied, Strategia’s <MicroSure /> product line bonds to surfaces creating a nano-barrier that “mechanically kills” harmful pathogens that make you sick. <MicroSure /> product line is one of many of Strategia’s products that fulfill its mission to make a global impact by saving lives immediately. </p>
      </div>
    </Container>
  </Index>
)

export default OurStoryPage
